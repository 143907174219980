<template>
  <v-app>
    <the-nav-bar :campusId="campusId" />
    <v-main>
      <router-view @confirmCampus="confirmCampus" />
    </v-main>
    <the-footer />
  </v-app>
</template>

<script>
import TheNavBar from "./components/TheNavBar.vue";
import TheFooter from "./components/TheFooter.vue";

export default {
  name: "App",

  components: {
    TheNavBar,
    TheFooter,
  },
  data() {
    return {
      campusId: 0,
      changeRouteTransition: "slide-left",
    };
  },
  methods: {
    confirmCampus(campus) {
      this.campusId = campus;
    },
  },
};
</script>

<style>
:root {
  --main-bg-color: #ff5900;
}

#app {
  background-color: #f5f5f5;
}

.section-title {
  color: var(--main-bg-color);
  font-size: 2em;
}

hr.main-hr {
  border-top: 3px solid var(--main-bg-color);
  margin-bottom: 16px;
}

[class^="swal2"] {
  font-family: "Roboto", sans-serif;
}
</style>
