<template>
  <v-footer :padless="padless" class="pt-0 pb-0 pl-0 pr-0" color="primary">
    <v-container>
      <v-row>
        <!-- <v-col cols="12" md="6">
          <v-btn v-for="icon in icons" :key="icon" class="mx-2" icon>
            <v-icon size="24px" color="white"> {{ icon }} </v-icon>
          </v-btn>
        </v-col> -->
        <v-col cols="12" class="white--text text-center">
          <p>{{ new Date().getFullYear() }} — <strong>An&aacute;huac</strong></p>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>
<script>
export default {
  name: "TheFooter",
  data: () => ({
    icons: ["mdi-facebook", "mdi-instagram", "mdi-youtube", "mdi-twitter"],
    items: ["default", "absolute", "fixed"],
    padless: false,
    variant: "default",
  }),
  computed: {
    localAttrs() {
      const attrs = {};

      if (this.variant === "default") {
        attrs.absolute = false;
        attrs.fixed = false;
      } else {
        attrs[this.variant] = true;
      }
      return attrs;
    },
  },
};
</script>

<style scoped></style>
