import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "@/views/Home.vue";
// import Planes from "@/views/Planes.vue";
// import Solicitud from "@/views/Solicitud.vue";
// import Coordinadores from "@/views/Coordinadores.vue";
// import PreguntasFrecuentes from "@/views/PreguntasFrecuentes.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
  },
  {
    path: "/planes",
    name: "planes",
    component: () =>
      import(/* webpackChunkName: "planes" */ "@/views/Planes.vue"),
  },
  {
    path: "/solicitud",
    name: "solicitud",
    component: () =>
      import(/* webpackChunkName: "solicitud" */ "@/views/Solicitud.vue"),
  },
  {
    path: "/coordinadores",
    name: "coordinadores",
    // component: Coordinadores,
    component: () =>
      import(
        /* webpackChunkName: "coordinadores" */ "@/views/Coordinadores.vue"
      ),
  },
  {
    path: "/preguntas-frecuentes",
    name: "preguntas-frecuentes",
    component: () =>
      import(
        /* webpackChunkName: "preguntas-frecuentes" */ "@/views/PreguntasFrecuentes.vue"
      ),
  },
  {
    path: "/primer-semestre",
    name: "primer-semestre",
    component: () =>
      import(
        /* webpackChunkName: "preguntas-frecuentes" */ "@/views/PrimerSemestre.vue"
      ),
  },

  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
